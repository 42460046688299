import React from "react"

import {
  Card,
  Header,
  Footer,
  Body,
  Picture,
  SecondCard,
  Title,
  Cta,
} from "./styled"
import { Link } from "gatsby"

const sample = "/images/sample.png"

const Guide = props => {
  return (
    <Link to={`/${props.guide.slug}`}>
      <Card isHomepage="true">
        <Header categories={props.guide.categories} />
        <Picture
          loading="eager"
          fadeIn={false}
          fluid={(props.guide.image && props.guide.image.fluid) || sample}
          alt={
            (props.guide.image && props.guide.image.description) ||
            "Guide d'achat"
          }
        />
        <Body>
          <Title>{props.guide.title}</Title>
        </Body>

        <Footer>
          <Cta>En savoir plus</Cta>
        </Footer>
      </Card>
      <SecondCard className="second" isHomepage={props.isHomePage} />
    </Link>
  )
}
export default Guide
